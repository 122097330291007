<template>
	<div>

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <b-container>
      <b-card  title="Import outlets" class="card-icon mb-30 p-16">
        <b-row>
          <b-col lg="12" md="12" sm="12">

            <div class="row">

              <div class="col-md-3">

              </div>

              <div class="col-md-6">
                <file-pond
                  @updatefiles="handleFileSelected"
                  v-bind:instantUpload="false"
                  class-name="file-upload"
                  style="cursor: pointer;"
                  ref="newsFiles"
                  label-idle="Select or drop an excel file."
                  v-bind:allow-multiple="false"
                  v-bind:max-files="1"
                  v-bind:files="myFiles"
                />

                <div class="row" >
                  <div class="col-md-12">
                    <hr/>
                  </div>
                </div>

                <div class="row" >
                  <div class="col-md-12">
                    <button @click="saveImportedFile" class="btn btn-danger float-right" style="color:#fff;" > Submit </button>
                  </div>
                </div>



              </div>

              <div class="col-md-3">

              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-container>




	</div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";
	import vueFilePond from 'vue-filepond';
	import 'filepond/dist/filepond.min.css';
	import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
	import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
	import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css'

	const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

	export default {
		name: 'customers',
		data () {
			return {
        isLoading: false,
        fullPage: true,
				myFiles:[],
				file:"",
			}

		},
		computed: {

		},
		mounted (){
		},
		components: {
      Loading
		},

		methods: {
      ...mapActions(["importOutlets"]),
			saveImportedFile(){

				let file = null;

				if(this.myFiles.length > 0){
					file = this.myFiles[0];
				}

				this.isLoading = true;

				let formData = new FormData();
				formData.append('excelFile', file);

        this.importOutlets(formData).then(() => {
          self.isLoading = false;

          self.$bvToast.toast("Outlets imported successfully.", {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: false
          });

          this.$router.push("/app/pages/outlets");

          //self.getOutletData();

        })
          .catch(error => {

            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });

          })


			},
			handleFileSelected(files){

				let file_exists = [];

				this.myFiles = [];

				let self = this;

				files.forEach(function (file) {

					if(!file_exists.includes(file.file.name)){
						self.myFiles.push(file.file);
						file_exists.push(file.file.name)
					}
				})

			}
		}
	}
</script>
